<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="660px"
    @close="close"
  >
    <el-form
      ref="form"
      class="editForm"
      label-width="120px"
      :model="form"
      :rules="rules"
    >
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form-item
            label="中文品名:"
            prop="reportCnName"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.reportCnName"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="英文品名:"
            prop="reportEnName"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.reportEnName"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="中国海关编码:"
            prop="hsCodeCn"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.hsCodeCn"
              maxlength="20"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="状态:" prop="status" style="width: 300px">
            <el-select
              v-model="form.status"
              placeholder="请选择类型"
              style="width: 180px"
            >
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="备注:" prop="remark" style="width: 600px">
            <el-input
              v-model="form.remark"
              maxlength="200"
              placeholder="请输入"
              type="textarea"
            />
          </el-form-item>
        </vab-query-form-top-panel>
      </vab-query-form>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    cmsHighRiskDataCreate,
    cmsHighRiskDataUpdate,
  } from '@/api/highRiskDataManagement.js'
  export default {
    props: {
      modulelist: Function,
    },
    data() {
      return {
        title: '',
        dialogFormVisible: false,
        statusList: [
          { id: 1, name: '启用' },
          { id: 0, name: '禁用' },
        ],
        form: {
          id: '',
          reportCnName: '',
          reportEnName: '',
          hsCodeCn: '',
          remark: '',
          status: '',
        },
        rules: {
          // reportCnName: [
          //   { required: true, trigger: 'blur', message: '请输入申报中文品名' },
          // ],
          // reportEnName: [
          //   { required: true, trigger: 'blur', message: '请输入申报英文品名' },
          // ],
          // hsCodeCn: [
          //   { required: true, trigger: 'blur', message: '请输入中国海关编码' },
          // ],
          status: [
            { required: true, trigger: 'change', message: '请选择状态' },
          ],
        },
      }
    },
    mounted() {},
    methods: {
      async showEdit(row) {
        if (!row) {
          this.title = '新增高危资料'
        } else {
          this.title = '编辑高危资料'
          const form = {
            id: row.id,
            reportCnName: row.reportCnName,
            reportEnName: row.reportEnName,
            hsCodeCn: row.hsCodeCn,
            remark: row.remark,
            status: row.status,
          }
          this.form = Object.assign({}, form)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.title == '新增高危资料') {
              const res = await cmsHighRiskDataCreate(this.form)
              if (res.code == '200') {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                await this.close()
                await this.modulelist()
              } else {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              }
            } else if (this.title == '编辑高危资料') {
              const res = await cmsHighRiskDataUpdate(this.form)
              if (res.code == '200') {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                await this.close()
                await this.modulelist()
              } else {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              }
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  html body .el-tag + .el-tag,
  html body[class*='vab-theme-'] .el-tag + .el-tag {
    margin-left: 0px;
  }
  .tag {
    border: 1px solid #dedede;
    height: 100px;
  }
  .sel-option {
    height: auto;
    max-height: 200px;
    overflow: auto;
    background-color: #fff;
    cursor: pointer;
    font-weight: normal !important;
    padding: 0 5px;
  }

  .editForm {
    ::v-deep {
      .el-form-item:first-child {
        margin-bottom: 20px !important;
      }
      .el-form-item + .el-form-item {
        margin-bottom: 20px !important;
      }
    }
  }
</style>
