import request from '@/utils/request'
let resquest = '/api/'

//高危资料-分页
export function cmsHighRiskDataPage(data) {
  return request({
    url: `${resquest}cmsHighRiskData/page`,
    method: 'post',
    data,
  })
}

//高危资料-创建
export function cmsHighRiskDataCreate(data) {
  return request({
    url: `${resquest}cmsHighRiskData/create`,
    method: 'post',
    data,
  })
}

//高危资料-修改
export function cmsHighRiskDataUpdate(data) {
  return request({
    url: `${resquest}cmsHighRiskData/update`,
    method: 'post',
    data,
  })
}

//高危资料-删除
export function cmsHighRiskDataDelete(data) {
  return request({
    url: `${resquest}cmsHighRiskData/delete`,
    method: 'post',
    data,
  })
}

//高危资料-导出
export function exportCmsHighRiskData(data) {
  return request({
    url: `${resquest}cmsHighRiskData/export`,
    method: 'post',
    data,
  })
}

//高危资料-导入
export function importCmsHighRiskData(data) {
  return request({
    url: `${resquest}cmsHighRiskData/import`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: localStorage.getItem('token'),
    },
  })
}
