var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "660px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "editForm",
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "vab-query-form-top-panel",
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "中文品名:", prop: "reportCnName" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.reportCnName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "reportCnName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.reportCnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "英文品名:", prop: "reportEnName" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.reportEnName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "reportEnName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.reportEnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "中国海关编码:", prop: "hsCodeCn" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.hsCodeCn,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "hsCodeCn",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.hsCodeCn"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "状态:", prop: "status" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { placeholder: "请选择类型" },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        },
                        _vm._l(_vm.statusList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { label: "备注:", prop: "remark" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          type: "textarea"
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }