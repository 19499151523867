var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: {
                    inline: true,
                    "label-width": "100px",
                    model: _vm.queryForm
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申报中文品名", prop: "reportCnName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.reportCnName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "reportCnName", $$v)
                          },
                          expression: "queryForm.reportCnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "申报英文品名", prop: "reportEnName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.reportEnName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "reportEnName", $$v)
                          },
                          expression: "queryForm.reportEnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "中国海关编码", prop: "hsCodeCn" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.hsCodeCn,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "hsCodeCn", $$v)
                          },
                          expression: "queryForm.hsCodeCn"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.reset("queryForm")
                            }
                          }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.addOrEdit }
                },
                [_vm._v(" 新增 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.queryForm.idList.length > 0,
                    size: "mini",
                    type: "danger"
                  },
                  on: { click: _vm.deleteData }
                },
                [_vm._v(" 删除 ")]
              ),
              _c("uploadExcel", {
                attrs: {
                  "down-url": "高风险资料导入模版.xls",
                  "upload-aciton": _vm.bt
                }
              }),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingExPort,
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.exPort }
                },
                [_vm._v(" 导出 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.openColumnSettings }
                },
                [_vm._v(" 设置列 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 318px)" } },
        [
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableRef",
              attrs: {
                id: "cmsHighRiskDataTable",
                border: "",
                "checkbox-config": { trigger: "row", range: true },
                "custom-config": _vm.customConfig,
                data: _vm.list,
                height: "100%",
                "keep-source": true,
                "row-config": { isHover: true, isCurrent: true, height: 35 },
                "scroll-y": { enabled: true },
                "show-overflow": ""
              },
              on: {
                "checkbox-all": _vm.setSelectRows,
                "checkbox-change": _vm.setSelectRows
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  type: "checkbox",
                  width: "55"
                }
              }),
              _vm.selectedColumns.includes("reportCnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "reportCnName",
                      "min-width": "120",
                      title: "中文品名"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("reportEnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "reportEnName",
                      "min-width": "120",
                      title: "英文品名"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("hsCodeCn")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "hsCodeCn",
                      "min-width": "120",
                      title: "中国海关编码"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("status")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "status",
                      "min-width": "80",
                      title: "状态"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.status === 0
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { type: "info" } },
                                        [_vm._v("禁用")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              row.status === 1
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("启用")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      665714192
                    )
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("remark")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "remark",
                      "min-width": "200",
                      title: "备注"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("createBy")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "createBy",
                      "min-width": "100",
                      title: "创建人"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("createTime")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "createTime",
                      "min-width": "180",
                      title: "创建时间"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("updateBy")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "updateBy",
                      "min-width": "100",
                      title: "修改人"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("updateTime")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "updateTime",
                      "min-width": "180",
                      title: "修改时间"
                    }
                  })
                : _vm._e(),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "option",
                  fixed: "right",
                  "min-width": "100",
                  title: "操作"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.addOrEdit(row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _vm.isDetailsShow
        ? _c(
            "div",
            { staticClass: "posit" },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "15px",
                    top: "15px",
                    "z-index": "9999",
                    cursor: "pointer",
                    "font-size": "15px",
                    "font-weight": "600"
                  },
                  on: { click: _vm.formSmbiClose }
                },
                [
                  _vm._v(" 返回上一级 "),
                  _c("vab-icon", { attrs: { icon: "arrow-go-back-line" } })
                ],
                1
              ),
              _c("Details", {
                ref: "details",
                attrs: { id: _vm.id, dialog_visible: _vm.isDetailsShow },
                on: { dialogVisibleEvent: _vm.formSmbiClose }
              })
            ],
            1
          )
        : _vm._e(),
      _c("table-edit", {
        ref: "edit",
        attrs: {
          modulelist: _vm.queryList,
          "system-guid": _vm.queryForm.systemGuid
        }
      }),
      _c("frequency-modal", {
        attrs: { id: _vm.frequencyId, open: _vm.frequencyOpen },
        on: {
          "update:open": function($event) {
            _vm.frequencyOpen = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }